import { lazy } from 'react';
const Home = lazy(() => import('@/components/layout/home'));
const cityIntroduce = lazy(() => import('@/views/cityIntroduce/cityIntroduce'));
const Encyclopaedic = lazy(() => import('@/views/encyclopaedic/encyclopaedic'));
const IntrodeceForm = lazy(() => import('@/views/cityIntroduce/introdeceForm'));
const Section = lazy(() => import('@/views/section/section'));
const Article = lazy(() => import('@/views/topic/article'));
const FormArticle = lazy(() => import('@/views/article/formArticle'));
const RoleManage = lazy(() => import('@/views/roleManage/roleManage'));
const UserManage = lazy(() => import('@/views/userManage/userManage'));
const OperationRecord = lazy(() => import('@/views/operationRecord/recordList'));
const SetCenter = lazy(() => import('@/views/setCenter/setCenter'));
// const LeadWebhook = lazy(() => import('@/views/setCenter/leadWebhook'));
const Register = lazy(() => import('@/views/customer/register'));
const CustomerInfor = lazy(() => import('@/views/customer/customerInfo')); // 客户基础信息
const PublicCustomer = lazy(() => import('@/views/customer/publicCustomer')); // 客户公海
const DeleteCustomer = lazy(() => import('@/views/customer/deleteCustomer')); // 客户回收站
const TrackingList = lazy(() => import('@/views/customer/trackingList')); // 对应记录列表
const EditCustomerInfo = lazy(() => import('@/views/customer/editCustomerInfo')); // 编辑客户基础信息
const AddCustomerInfo = lazy(() => import('@/views/customer/addCustomer')); // 创建客户基础信息
const CustomerUnregister = lazy(() => import('@/views/customer/customerUnregister'));
const BlackList = lazy(() => import('@/views/customer/blackList')); // 黑名单；列表
const Agent = lazy(() => import('@/views/agent/agent'));
const AgentLogin = lazy(() => import('@/views/agent/agentLogin')); // 代理商登录列表
const AgentUnregister = lazy(() => import('@/views/agent/agentUnregister'));
const AddAgent = lazy(() => import('@/views/agent/addAgent'));
const ChildAgent = lazy(() => import('@/views/agent/childAgent')); // 创建及修改子代理商账号
const Banner = lazy(() => import('@/views/resources/banner')); // banner
const DeployBanner = lazy(() => import('@/views/resources/deployBanner')); // banner配置
const ReportDownload = lazy(() => import('@/views/resources/reportDownload')); // 报告下载
const CustomerDetail = lazy(() => import('@/views/customer/customerDetail')); // 注册用户管理详情
const RequirementInput = lazy(() => import('@/views/customer/requirementInput')); // 客户需求录入
const Keyword = lazy(() => import('@/views/keyword/keyword')); // 搜索词
const LiveVideo = lazy(() => import('@/views/liveVideo/liveVideo')); // 直播视频
const DeployVideo = lazy(() => import('@/views/liveVideo/deployVideo')); // 视频配置
const Tracker = lazy(() => import('@/views/operate/tracker')); // 表单埋点管理
const Feedback = lazy(() => import('@/views/clue/feedback')); // 线索审核
const AccountStatement = lazy(() => import('@/views/clue/accountStatement')); // Feedback
const Inquiry = lazy(() => import('@/views/operate/inquiry')); // 垂询列表
const AllInquiry = lazy(() => import('@/views/operate/allInquiry')); // 所有垂询列表
const ChatInquiry = lazy(() => import('@/views/operate/chatInquiry')); // chat垂询列表
const InquiryDetail = lazy(() => import('@/views/operate/inquiry-detail')); // 垂询详情
const Dashboard = lazy(() => import('@/views/echart/dashboard')); // dashboard
const EstateTrend = lazy(() => import('@/views/echart/estateTrend')); // 房源趋势
const CustomerTrend = lazy(() => import('@/views/echart/customerTrend')); // 客户趋势
const Overview = lazy(() => import('@/views/echart/customerAnalysis/overview')); // 客户分析总览
const SalesBriefing = lazy(() => import('@/views/echart/customerAnalysis/salesBriefing')); // 销售简报
// const Channel = lazy(() => import('@/views/echart/customerAnalysis/channel')); // 渠道分析
const ClueTrend = lazy(() => import('@/views/echart/clueTrend')); // 线索趋势
const AgentTrend = lazy(() => import('@/views/echart/agentTrend')); // 代理商趋势
const HomeSection = lazy(() => import('@/views/resources/homeSection')); // 首页版块
const InformationList = lazy(() => import('@/views/notification/information/information-list')); // 资讯动态管理
const InformationEdit = lazy(() => import('@/views/notification/information/information-edit')); // 资讯编辑
const MansionList = lazy(() => import('@/views/house/mansion/mansion-list')); // 楼盘添加申请
const MansionManagement = lazy(() => import('@/views/house/mansion/mansion-management')); // 楼盘管理
const MansionConfirm = lazy(() => import('@/views/house/mansion/mansion-confirm')); // 楼盘添加申请
const EstateList = lazy(() => import('@/views/house/mansion/estate-list')); // 房源查询
const EstateDetail = lazy(() => import('@/views/house/mansion/estate-detail')); // 房源详情
const News = lazy(() => import('@/views/media/news/index')); // 文章列表
const NewsEdit = lazy(() => import('@/views/media/news/news-edit')); // 编辑文章
const NewsTag = lazy(() => import('@/views/media/news/newsTag')); // 标签管理
const SpecialEstateList = lazy(() => import('@/views/specialEstate/list')); // 大型物件页一览
const SpecialEstateEdit = lazy(() => import('@/views/specialEstate/edit')); // 添加大型物件页
const SpecialList = lazy(() => import('@/views/specialEstate/specialList')); // 房源专辑一览
const FairEstate = lazy(() => import('@/views/specialEstate/fairEstate')); // 添加特辑页
const TopicTitleList = lazy(() => import('@/views/topic/topicTitleList')); // 标题模板列表
const KeywordsList = lazy(() => import('@/views/topic/keywordsList')); // 关键词列表
const EditArticle = lazy(() => import('@/views/topic/editArticle')); // 编辑专题页文章
// const TopicCategory = lazy(() => import('@/views/topic/category')); // 专题分类
const TagList = lazy(() => import('@/views/topic/tagList')); // 标签管理
// const StatsSemList = lazy(() => import('@/views/statsSem/statsSemList')); // sem列表
const TopicDescList = lazy(() => import('@/views/topic/topicDescList')); // 介绍文模板列表
const AppVersion = lazy(() => import('@/views/system/version')); // app版本管理
const Investment = lazy(() => import('@/views/media/investment/index')); // 投资讲座
const InvestEdit = lazy(() => import('@/views/media/investment/invest-edit')); // 编辑投资讲座
const DistrictEdit = lazy(() => import('@/views/regionalManage/editForm')); // 编辑区域
const CountryList = lazy(() => import('@/views/regionalManage/countryList')); // 国家列表管理
const RegionalList = lazy(() => import('@/views/regionalManage/regionalList')); // 一级区域管理
const TwoLevelList = lazy(() => import('@/views/regionalManage/twoLevelList')); // 二级区域列表
const ThreeLevelList = lazy(() => import('@/views/regionalManage/threeLevelList')); // 三级区域列表
const CountryEdit = lazy(() => import('@/views/regionalManage/editCountry')); // 三级区域列表
const CurrencyList = lazy(() => import('@/views/regionalManage/currencyList')); // 货币管理
/* 房源管理 */
const Audit = lazy(() => import('@/views/estate/audit'));
const Special = lazy(() => import('@/views/estate/special')); // 特辑管理
const EditSpecial = lazy(() => import('@/views/estate/deploySpecial')); // 编辑特辑
const Rental = lazy(() => import('@/views/estate/rental')); // 租房管理
const ScheduleList = lazy(() => import('@/views/customer/scheduleList'));
const PersonalCenter = lazy(() => import('@/views/login/personalCenter')); // 个人中心
const routes = [
    {
        component: Home,
        children: [
            {
                path: '/dashboard',
                title: 'ToB Dashboard',
                meta: {
                    show: true,
                    icon: 'icon-jianzhushejijindu-copy',
                },
                component: Dashboard,
                children: [
                    {
                        path: 'estateTrend',
                        title: 'EstateTrend',
                        meta: {
                            show: false,
                            icon: 'icon-dashboard',
                        },
                        component: EstateTrend
                    },
                    {
                        path: 'customerTrend',
                        title: '客户趋势',
                        meta: {
                            show: false,
                            icon: 'icon-dashboard',
                        },
                        component: CustomerTrend
                    },
                    {
                        path: 'clueTrend',
                        title: '线索趋势',
                        meta: {
                            show: false,
                            icon: 'icon-dashboard',
                        },
                        component: ClueTrend
                    },
                    {
                        path: 'agentTrend',
                        title: '代理商趋势',
                        meta: {
                            show: false,
                            icon: 'icon-dashboard',
                        },
                        component: AgentTrend
                    }
                ]
            },
            {
                path: '/analysis',
                title: 'ToC Dashboard',
                meta: {
                    icon: 'icon-toc',
                    isPublic: true
                },
                children: [
                    {
                        path: 'overview',
                        title: '客户分析',
                        meta: {
                            icon: '',
                            show: true,
                            isPublic: true
                        },
                        component: Overview
                    },
                    {
                        path: 'salesBriefing',
                        title: '销售简报',
                        meta: {
                            icon: '',
                            show: true,
                            isPublic: true
                        },
                        component: SalesBriefing
                    },
                    // {
                    //     path: 'channel',
                    //     title: '渠道分析',
                    //     meta: {
                    //         icon: '',
                    //         show: true,
                    //         isPublic: true
                    //     },
                    //     component: Channel
                    // },
                ]
            },
            {
                path: '/schedule-list',
                title: '日程列表',
                meta: {
                    show: false,
                    icon: 'icon-dashboard',
                },
                component: ScheduleList
            },
            {
                path: '/personal-center',
                title: '个人中心',
                meta: {
                    show: false,
                    icon: '',
                },
                component: PersonalCenter
            },
            {
                path: '/agent',
                title: '代理商管理',
                meta: {
                    icon: 'icon-district'
                },
                children: [
                    {
                        path: 'list',
                        title: '代理商列表',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: Agent,
                        children: [
                            {
                                path: 'addAgent',
                                title: '创建代理商',
                                meta: {
                                    icon: '',
                                    show: false,
                                },
                                component: AddAgent
                            },
                            {
                                path: 'childAgent',
                                title: '创建及修改子代理商账号',
                                meta: {
                                    icon: '',
                                    show: false
                                },
                                component: ChildAgent
                            },
                        ]
                    },
                    {
                        path: 'agentLogin',
                        title: '代理商登录列表',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: AgentLogin
                    },
                    {
                        path: 'agentUnregister',
                        title: '注销审核',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: AgentUnregister
                    },
                ]
            },
            {
                path: '/inquiry',
                title: '垂询管理',
                meta: {
                    icon: 'icon-inquiry1'
                },
                children: [
                    {
                        path: 'all-inquiry',
                        title: '全部垂询',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: AllInquiry,
                        children: [
                            {
                                path: 'inquiry-detail',
                                title: '垂询详情',
                                meta: {
                                    icon: '',
                                    show: false
                                },
                                component: InquiryDetail
                            }
                        ]
                    },
                    {
                        path: 'estate-inquiry',
                        title: '买卖垂询',
                        meta: {
                            icon: '',
                            show: true,
                        },
                        component: Inquiry,
                        children: [
                            {
                                path: 'inquiry-detail',
                                title: '垂询详情',
                                meta: {
                                    icon: '',
                                    show: false
                                },
                                component: InquiryDetail
                            },
                            {
                                path: 'inquiryInfo/:inquiryId',
                                title: '垂询信息',
                                meta: {
                                    icon: '',
                                    show: false
                                },
                                component: EditCustomerInfo
                            },
                        ]
                    },
                    {
                        path: 'rental',
                        title: '租赁垂询',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: Rental,
                        children: [
                            {
                                path: 'rental-detail',
                                title: '垂询详情',
                                meta: {
                                    icon: '',
                                    show: false
                                },
                                component: InquiryDetail
                            },
                        ]
                    },
                    {
                        path: 'chat-inquiry',
                        title: 'Chat垂询',
                        meta: {
                            icon: '',
                            show: true,
                        },
                        component: ChatInquiry
                    },
                    {
                        path: 'tracker',
                        title: '埋点管理',
                        meta: {
                            icon: '',
                            show: true,
                        },
                        component: Tracker
                    }
                ]
            },
            {
                path: '/customer',
                title: '客户管理',
                meta: {
                    icon: 'icon-customer'
                },
                children: [
                    {
                        path: 'publicCustomer',
                        title: '客户公海',
                        meta: {
                            icon: '',
                            show: true,
                            breadcrumb: 1
                        },
                        component: PublicCustomer
                    },
                    {
                        path: 'customerInfor',
                        title: '垂询客户',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: CustomerInfor
                    },
                    {
                        path: 'editCustomerInfo/:id',
                        title: '客户信息',
                        meta: {
                            icon: '',
                            show: false,
                        },
                        component: EditCustomerInfo
                    },
                    {
                        path: 'addCustomerInfo',
                        title: '创建客户信息',
                        meta: {
                            icon: '',
                            show: false,
                            breadcrumb: 1
                        },
                        component: AddCustomerInfo
                    },
                    {
                        path: 'tracking',
                        title: '对应记录',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: TrackingList
                    },
                    {
                        path: 'deleteCustomer',
                        title: '客户回收站',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: DeleteCustomer
                    },
                    {
                        path: 'register',
                        title: '注册客户',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: Register,
                        children: [
                            {
                                path: 'detail',
                                title: '注册用户管理详情',
                                meta: {
                                    icon: '',
                                    show: false
                                },
                                component: CustomerDetail
                            },
                        ]
                    },
                    {
                        path: 'requirementInput',
                        title: '需求录入列表',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: RequirementInput
                    },
                    {
                        path: 'unregister',
                        title: '注销客户',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: CustomerUnregister
                    },
                    {
                        path: 'blackList',
                        title: '黑名单',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: BlackList
                    }

                ]
            },
            {
                path: '/house',
                title: '房源管理',
                meta: {
                    icon: 'icon-house'
                },
                children: [
                    {
                        path: 'audit',
                        title: '新房审核',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: Audit
                    },
                    {
                        path: 'mansion-management',
                        title: '楼盘管理',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: MansionManagement,
                        children: [
                            {
                                path: 'mansion-management-edit',
                                title: '楼盘管理编辑',
                                meta: {
                                    icon: '',
                                    show: false
                                },
                                component: MansionConfirm
                            }
                        ]
                    },
                    {
                        path: 'mansion',
                        title: '楼盘申请',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: MansionList,
                        children: [
                            {
                                path: 'mansion-confirm',
                                title: '楼盘申请编辑',
                                meta: {
                                    icon: '',
                                    show: false
                                },
                                component: MansionConfirm
                            }
                        ]
                    },
                    {
                        path: 'estate-list',
                        title: '房源查询',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: EstateList,
                        children: [
                            {
                                path: 'estate-detail',
                                meta: {
                                    title: '房源详情',
                                    icon: '',
                                    show: false
                                },
                                component: EstateDetail
                            }
                        ]
                    },

                ]
            },
            {
                path: '/clue',
                title: '线索管理',
                meta: {
                    icon: 'icon-clue'
                },
                children: [
                    {
                        path: 'feedback',
                        title: '线索审核',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: Feedback
                    },
                    {
                        path: 'accountStatement',
                        title: '对账单',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: AccountStatement
                    }
                ]
            },
            {
                path: '/notification',
                title: '推送管理',
                meta: {
                    icon: 'icon-notification'
                },
                children: [
                    {
                        path: 'information-list',
                        title: '推送列表',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: InformationList,
                        children: [
                            {
                                path: 'information-edit',
                                title: '推送编辑',
                                meta: {
                                    icon: '',
                                    show: false
                                },
                                component: InformationEdit
                            }
                        ]
                    }
                ]
            },
            {
                path: '/article',
                title: '资讯管理',
                meta: {
                    icon: 'icon-article'
                },
                children: [
                    {
                        path: 'news-list',
                        title: '新闻资讯',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: News,
                        children: [
                            {
                                path: 'news-edit',
                                title: '编辑文章',
                                meta: {
                                    icon: '',
                                    show: false
                                },
                                component: NewsEdit
                            }
                        ]
                    },
                    {
                        path: 'news-tag',
                        title: '标签管理',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: NewsTag
                    },
                ]
            },
            {
                path: '/resources',
                title: '资源管理',
                meta: {
                    icon: 'icon-content'
                },
                children: [
                    {
                        path: 'banner',
                        title: '轮播图',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: Banner,
                        children: [
                            {
                                path: 'deployBanner',
                                title: '编辑页面',
                                meta: {
                                    icon: '',
                                    show: false
                                },
                                component: DeployBanner
                            },
                        ]
                    },
                    {
                        path: 'homeSection',
                        title: '首页版块',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: HomeSection
                    },
                    {
                        path: 'video',
                        title: '直播视频',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: LiveVideo,
                        children: [
                            {
                                path: 'deployVideo',
                                title: '直播配置',
                                meta: {
                                    icon: '',
                                    show: false
                                },
                                component: DeployVideo
                            },
                        ]
                    },
                    {
                        path: 'investment',
                        title: '投资讲座',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: Investment,
                        children: [
                            {
                                path: 'invest-edit',
                                title: '编辑页',
                                meta: {
                                    icon: '',
                                    show: false
                                },
                                component: InvestEdit
                            }
                        ]
                    },
                    {
                        path: 'keyword',
                        title: '搜索词',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: Keyword
                    },
                    {
                        path: 'reportDownload',
                        title: '趋势报告',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: ReportDownload
                    }
                ]
            },
            {
                path: '/regionalManage',
                title: '区域管理',
                meta: {
                    icon: 'icon-regional'
                },
                children: [
                    {
                        path: 'countryList',
                        title: '国家列表',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: CountryList,
                        children: [
                            {
                                path: 'regionalList',
                                title: '一级区域列表',
                                meta: {
                                    icon: 'icon-regional1',
                                    show: false
                                },
                                component: RegionalList
                            },
                            {
                                path: 'twoLevelList',
                                title: '二级区域列表',
                                meta: {
                                    icon: 'icon-regional1',
                                    show: false
                                },
                                component: TwoLevelList
                            },
                            {
                                path: 'threeLevelList',
                                title: '三级区域列表',
                                meta: {
                                    icon: 'icon-regional1',
                                    show: false
                                },
                                component: ThreeLevelList
                            },
                            {
                                path: 'country-edit',
                                title: '国家编辑页',
                                meta: {
                                    icon: 'icon-regional1',
                                    show: false
                                },
                                component: CountryEdit
                            }
                        ]
                    },
                    {
                        path: 'district-edit',
                        title: '区域编辑',
                        meta: {
                            icon: 'icon-regional1',
                            show: false
                        },
                        component: DistrictEdit
                    },
                    {
                        path: 'currencyList',
                        title: '货币管理',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: CurrencyList
                    }
                ]
            },
            {
                path: '/seo',
                title: 'SEO管理',
                meta: {
                    icon: 'icon-seo'
                },
                children: [
                    {
                        path: 'cityIntroduce',
                        title: '城市专题',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: cityIntroduce,
                        children: [
                            {
                                path: 'introdeceForm',
                                title: '新增城市介绍',
                                meta: {
                                    icon: '',
                                    show: false
                                },
                                component: IntrodeceForm
                            },
                            {
                                path: 'encyclopaedic',
                                title: '城市百科',
                                meta: {
                                    icon: '',
                                    show: false
                                },
                                component: Encyclopaedic,
                                children: [
                                    {
                                        path: 'formArticle',
                                        title: '文章',
                                        meta: {
                                            icon: '',
                                            show: false
                                        },
                                        component: FormArticle
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        path: 'section',
                        title: '城市详情分类',
                        meta: {
                            icon: '',
                            show: true,
                            breadcrumb: 3
                        },
                        component: Section
                    },
                    // {
                    //     path: '/article',
                    //     meta: {
                    //         title: '文章',
                    //         icon: 'icon-seo',
                    //         show: false,
                    //         breadcrumb: 1
                    //     },
                    //     component: Article
                    // },
                    {
                        path: 'topicTitleList',
                        title: '专题标题模板',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: TopicTitleList
                    },
                    {
                        path: 'topicDescList',
                        title: '专题介绍文模板',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: TopicDescList
                    },
                    {
                        path: 'tagList',
                        title: '专题标签',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: TagList
                    },
                    // {
                    //     path: '/topicCategory',
                    //     meta: {
                    //         title: '专题分类',
                    //         breadcrumb: 8,
                    //         icon: '',
                    //         show: true
                    //     },
                    //     component: TopicCategory
                    // },
                    {
                        path: 'keywordsList',
                        title: '百科关键词',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: KeywordsList
                    },
                    {
                        path: 'seo-article',
                        title: '百科文章',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: Article,
                        children: [
                            {
                                path: 'editArticle',
                                title: '专题文章',
                                meta: {
                                    icon: '',
                                    show: false
                                },
                                component: EditArticle
                            }
                        ]
                    },

                    // {
                    //     path: '/statsSemList',
                    //     meta: {
                    //         title: 'SEM',
                    //         icon: 'icon-seo',
                    //         show: true
                    //     },
                    //     component: StatsSemList
                    // }

                ]
            },
            {
                path: 'specialEstate',
                title: '活动管理',
                meta: {
                    icon: 'icon-house',
                },
                children: [
                    {
                        path: 'special',
                        title: '特辑列表',
                        meta: {
                            icon: '',
                            show: true,
                            breadcrumb: 1
                        },
                        component: Special,
                        children: [
                            {
                                path: 'edit-special',
                                title: '编辑特辑',
                                meta: {
                                    icon: '',
                                    show: false
                                },
                                component: EditSpecial
                            }
                        ]
                    },
                    // {
                    //     title: '大型物件页一览',
                    //     path: 'specialEstateList',
                    //     meta: {
                    //         icon: 'icon-mansion',
                    //         show: true
                    //     },
                    //     component: SpecialEstateList
                    // },
                    // {
                    //     path: 'specialEstateEdit',
                    //     title: '添加大物件',
                    //     meta: {
                    //         icon: '',
                    //         show: false
                    //     },
                    //     component: SpecialEstateEdit
                    // },
                    {
                        path: 'specialList',
                        title: '类似LP列表',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: SpecialList
                    },
                    // {
                    //     path: 'fairEstate',
                    //     title: '添加特辑页',
                    //     meta: {
                    //         icon: '',
                    //         show: false
                    //     },
                    //     component: FairEstate
                    // }
                ]
            },
            {
                path: '/system',
                title: '系统设置',
                meta: {
                    icon: 'icon-system'
                },
                children: [
                    {
                        path: 'version',
                        title: 'APP版本',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: AppVersion
                    },
                    {
                        path: 'role',
                        title: '角色管理',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: RoleManage
                    },
                    {
                        path: 'user',
                        title: '用户管理',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: UserManage
                    },
                    {
                        path: 'record',
                        title: '操作记录',
                        meta: {
                            icon: '',
                            show: true
                        },
                        component: OperationRecord
                    },
                    {
                        path: 'setCenter',
                        title: '设置中心',
                        meta: {
                            icon: '',
                            show: true,
                            breadcrumb: 4
                        },
                        component: SetCenter,
                        // children: [
                        //     {
                        //         path: 'leadWebhook',
                        //         title: 'Webhook 配置',
                        //         meta: {
                        //             icon: '',
                        //             show: false,
                        //             breadcrumb: 4
                        //         },
                        //         component: LeadWebhook
                        //     }
                        // ]
                    },
                ]
            },
            // {
            //     path: '',

            //     meta: {
            //         title: 'SEO',
            //         icon: 'icon-seo'
            //     },
            //    
            //     children: [
            //         {
            //             path: '/citySpecial',
            //             meta: {
            //                 title: '城市专题页'
            //             },
            //             children: [
            //                 {
            //                     path: '/cityIntroduce',
            //                     meta: {
            //                         title: '房产概况',
            //                         icon: 'icon-property',
            //                         show: true
            //                     },
            //                     component: cityIntroduce
            //                 },
            //                 {
            //                     path: '/introdeceForm',
            //                     meta: {
            //                         title: '新增城市介绍',
            //                         icon: 'icon-seo',
            //                         show: false
            //                     },
            //                     component: IntrodeceForm
            //                 },
            //                 {
            //                     path: '/encyclopaedic',
            //                     meta: {
            //                         title: '城市百科',
            //                         icon: 'icon-seo',
            //                         show: false
            //                     },
            //                     component: Encyclopaedic
            //                 },
            //                 {
            //                     path: '/section',
            //                     meta: {
            //                         title: '城市详情分类',
            //                         icon: 'icon-category',
            //                         show: true
            //                     },
            //                     component: Section
            //                 },
            //                 {
            //                     path: '/article',
            //                     meta: {
            //                         title: '文章',
            //                         icon: 'icon-seo',
            //                         show: false
            //                     },
            //                     component: Article  
            //                 },
            //                 {
            //                     path: '/formArticle',
            //                     meta: {
            //                         title: '文章表单',
            //                         icon: 'icon-seo',
            //                         show: false
            //                     },
            //                     component: FormArticle  
            //                 }
            //             ]
            //         }
            //     ]
            // },
            // {
            //     path: '/customer',
            //     meta: {
            //         title: '客户管理',
            //         icon: 'icon-customer2'
            //     },
            //    
            //     children: [
            //         {
            //         path: '/register',
            //             meta: {
            //                 title: '注册用户管理',
            //                 icon: 'icon-zhuce',
            //                 show: true
            //             },
            //             component: Register
            //         },
            //         {
            //         path: '/detail',
            //             meta: {
            //                 title: '注册用户管理详情',
            //                 icon: '',
            //                 show: false
            //             },
            //             component: CustomerDetail
            //         }
            //     ]
            // },
            // {
            //     path: '',
            //     meta: {
            //         title: '系统管理',
            //         icon: 'icon-system'
            //     },
            //    
            //     children: [
            //         {
            //         path: '/role',
            //             meta: {
            //                 title: '角色管理',
            //                 icon: 'icon-role',
            //                 show: true
            //             },
            //             component: RoleManage
            //         },
            //         {
            //         path: '/user',
            //             meta: {
            //                 title: '用户管理',
            //                 icon: 'icon-user',
            //                 show: true
            //             },
            //             component: UserManage
            //         }
            //     ]
            // },
        ]
    }

];

export default routes;